import { showSnack } from '@/helpers/globalHelpers';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { listProvider } from '@/store/modules/security/service/security.service';
import { createAssignedSupplier } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'SupplierAssignmentDialog',
	props: {
		agreementId: {
			type: [String, Number],
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		supplierId: {
			type: [String, Number],
			required: false,
		},
	},
	data() {
		return {
			users: [],
			valid: true,
			dialog: false,
			supplierUser: null,
			loadingSave: false,
			loadingUsers: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.getUsers();
				this.cleanForm();
			}
		},
	},
	methods: {
		getUsers: async function (params) {
			try {
				this.loadingUsers = true;
				const response = await listProvider(params);
				this.users = Array.isArray(response) ? response : [];
				if (this.supplierId) this.supplierUser = this.supplierId;
			} catch (error) {
				showSnack(error?.response?.data?.message, 'warning');
			} finally {
				this.loadingUsers = false;
			}
		},

		validateAssignment: async function () {
			const isvalidated = this.$refs.userForm?.validate();
			if (isvalidated) {
				const params = {
					header: {
						agreement_id: this.agreementId,
					},
					body: {
						user_provider_id: parseInt(this.supplierUser),
					},
				};
				this.sendAssignedSupplier(params);
			}
		},

		sendAssignedSupplier: async function (params) {
			try {
				this.loadingSave = true;
				await createAssignedSupplier(params);
				showSnack('¡Asignación finalizada con éxito!', 'success');
				this.$emit('update');
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingSave = false;
			}
		},

		cleanForm: function () {
			this.$nextTick(() => {
				this.supplierUser = null;
				if (this.$refs.userForm) this.$refs.userForm.reset();
			});
		},
	},
	components: {
		ValidationAlert,
	},
};
