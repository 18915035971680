export default {
	name: 'ContractSheetTable',
	props: {
		agreement: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {},
	components: {},
};
