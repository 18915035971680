var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"mb-8",attrs:{"id":"TemplateChangesTable"}},[_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-row',{staticClass:"px-4 py-2",attrs:{"justify":"space-between","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold text--secondary"},[_vm._v(" Cambios de plantilla: ")])]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$route.name === 'ReadAgreement')?_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","small":"","to":{
						name: 'AgreementEditor',
						params: { agreementId: _vm.agreementId },
					},"disabled":_vm.disabled}},[_vm._v(" Agregar Plantilla "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.templates?.length)?_c('v-data-table',{attrs:{"headers":_vm.headersAgreementWord,"items":_vm.templates,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_c('span',{domProps:{"textContent":_vm._s(_vm.formatDate(item.created_at))}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","icon":"","to":{
						name: _vm.redirectRouteName,
						params: { documentId: item.id },
					}}},[_c('v-icon',[_vm._v(" mdi-file-document-outline ")])],1)]}}],null,true)}):_c('div',{staticClass:"text-center mb-6 text--disabled"},[_vm._v(" No hay datos disponibles ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }