<template>
	<v-btn
		height="auto"
		color="success"
		class="text-none d-block py-2"
		depressed
		outlined
		block
		:loading="loadingSend"
		@click="confirmSend()"
	>
		<div :style="{ whiteSpace: 'normal' }">Aprobar contrato</div>
	</v-btn>
</template>

<script>
import { showSnack } from '@/helpers/globalHelpers';
import { replyAgreementNotification } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'SendApproveManagementButton',
	props: {
		agreementId: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			loadingSend: false,
		};
	},
	methods: {
		confirmSend: async function () {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Está seguro de aprobar el precontrato?',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
			});
			if (isConfirmed) {
				this.sendAgreement();
			}
		},

		sendAgreement: async function () {
			try {
				this.loadingSend = true;
				const params = { approved: true };
				await replyAgreementNotification(this.agreementId, params);
				showSnack('El precontrato ha sido enviado', 'success');
				this.$emit('update');
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingSend = false;
			}
		},
	},
};
</script>

<style lang="scss"></style>
