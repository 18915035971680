import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'DocumentsSection',
	props: {
		agreement: {
			type: [Object],
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			valid: null,
			dialog: false,
			headers: [
				{
					text: 'Nombre',
					value: 'name',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Fecha',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'right',
					class: 'primary--text',
				},
			],
			documentForm: {},
			nameRule: [(v) => !!v || 'Campo requerido'],
			loading: false,
			fileRule: [
				(v) => !!v || 'Campo requerido',
				(v) => this.validationExtension(v),
				(value) =>
					!value ||
					value.size < 2000000 ||
					'¡El tamaño de la imagen debe ser inferior a 2 MB!',
			],
		};
	},
	created() {
		this.getAgreementDocument();
	},
	mounted() {},
	computed: {
		...mapState('agreementDocument', ['agreementDocuments']),

		isDocumentsEmpty: function () {
			return !(
				Array.isArray(this.agreementDocuments) &&
				this.agreementDocuments.length > 0
			);
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.$nextTick(() => {
					if (this.$refs?.documentForm) this.$refs.documentForm.reset();
				});
			}
		},
	},
	methods: {
		...mapActions('agreementDocument', [
			'listAgreementDocument',
			'createAgreementDocument',
			'cleanAgreementDocuments',
		]),

		formatDate,

		getAgreementDocument() {
			this.listAgreementDocument({ agreement_id: this.agreement?.id });
		},

		validationExtension(value) {
			const nombreArchivo = value?.name || '';
			return (
				nombreArchivo.toLowerCase().endsWith('.pdf') ||
				'Por favor, seleccione un archivo PDF.'
			);
		},

		async saveDocument() {
			const isvalidated = this.$refs.documentForm.validate();
			if (isvalidated) {
				this.loading = true;
				let formData = new FormData();
				formData.append('name', this.documentForm?.name);
				formData.append('agreement_id', this.agreement?.id);
				formData.append(
					'file',
					this.documentForm?.file,
					this.documentForm?.file?.name
				);
				const { ok, response, error } = await this.createAgreementDocument(
					formData
				);
				if (ok) {
					this.getAgreementDocument();
					this.showSnack(response.message, 'success');
					this.$nextTick(() => {
						this.documentForm.name = null;
						this.documentForm.file = null;
						this.dialog = false;
						if (this.$refs.documentForm) this.$refs.documentForm.reset();
					});
					this.$store.dispatch('agreement/setFlagRelevantChanges', true);
				} else {
					this.showSnack(error?.data?.message);
				}
				this.loading = false;
			}
		},

		showSnack(message = [], color = 'error') {
			message = Array.isArray(message) ? message.join('<br> ') : message;
			$EventBus.$emit('showSnack', color, message);
		},
	},
	beforeDestroy() {
		this.cleanAgreementDocuments();
	},
	components: {},
};
