import { formatDate } from '@/helpers/globalHelpers';
import { mapState, mapActions } from 'vuex';
export default {
	name: 'ListObservations',
	props: {
		value: {
			type: [Object],
			required: true,
		},
	},
	data() {
		return {};
	},
	created() {
		if (this.agreement?.lastNotification?.id) {
			this.listAgreementObservation({
				agreement_id: this.agreement?.id,
				pagination: true,
				per_page: 5,
			});
		}
	},
	mounted() {},
	computed: {
		...mapState('agreementObservation', ['agreementObservations']),
		agreement: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		...mapActions('agreementObservation', ['listAgreementObservation']),

		formatDate,

		cutText(texto = '', longitudMaxima = 15) {
			if (texto && texto.length > longitudMaxima) {
				return texto.slice(0, longitudMaxima) + '...';
			} else {
				return texto;
			}
		},
	},
	components: {},
};
