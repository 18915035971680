import { showSnack } from '@/helpers/globalHelpers';
import { replyAgreementNotification } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'SendToLegalBox',
	props: {
		agreementId: {
			type: [String, Number],
			required: true,
		},
		agreementNotificationId: {
			type: [String, Number],
			required: true,
		},
		agreementStatus: {
			type: [String],
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			loadingApprove: false,
			loadingObservation: false,
			enableObservationForm: false,
			observationValue: null,
			configSwal: {
				title: null,
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
			},
		};
	},
	computed: {
		canReply: function () {
			const hasPermission =
				this.$store.getters['auth/hasRole']('Usuario Proveedor');
			return this.agreementStatus === 'PROVEEDOR_APROBACION' && hasPermission;
		},
	},
	watch: {},
	created() {},
	methods: {
		confirmApprove: async function () {
			const { isConfirmed } = await this.$swal.fire({
				...this.configSwal,
				text: '¿Aprobar precontrato?',
			});
			if (isConfirmed) {
				this.loadingApprove = true;
				this.sendReply({ approved: true });
			}
		},

		confirmObservation: async function () {
			const { isConfirmed } = await this.$swal.fire({
				...this.configSwal,
				text: '¿Estás seguro que quieres enviar la observación del precontrato?',
			});
			if (isConfirmed) {
				this.loadingObservation = true;
				this.sendReply({
					approved: false,
					observation: this.observationValue,
				});
			}
		},

		sendReply: async function (bodyParams) {
			try {
				await replyAgreementNotification(this.agreementId, bodyParams);
				showSnack(
					'El precontrato ha sido enviado al área de Abastecimiento',
					'success'
				);
				this.$emit('update');
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.enableObservationForm = false;
				this.loadingApprove = false;
				this.loadingObservation = false;
				this.observationValue = null;
			}
		},
	},
	beforeDestroy() {},
	components: {},
};
