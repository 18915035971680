<template>
	<div>
		<v-btn
			color="primary"
			class="my-2"
			small
			icon
			:loading="loading"
			@click="openFileDialog"
		>
			<v-icon>mdi-upload</v-icon>
		</v-btn>
		<input
			style="display: none"
			ref="fileInput"
			accept=".pdf, .PDF"
			type="file"
			@change="onFileChange"
		/>
	</div>
</template>
<script>
import { createAgreementAnnex } from '@/store/modules/agreement/service/agreement.service';
import { showSnack } from '@/helpers/globalHelpers';
export default {
	name: 'AnnexUpload',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		annex: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		files: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		computedDateFormatted() {
			return this.formatDate(this.date);
		},
	},
	methods: {
		openFileDialog() {
			this.$refs.fileInput.click();
		},

		onFileChange(event) {
			const file = event?.target?.files[0];
			if (file) this.sendCreateAgreementAnnex(file);
		},

		isPDF(file) {
			if (file) {
				const extension = file.name.split('.').pop().toLowerCase();
				if (extension !== 'pdf') {
					showSnack('Por favor, selecciona un archivo PDF', 'warning');
					return false;
				} else {
					return true;
				}
			}
			return false;
		},

		async sendCreateAgreementAnnex(file) {
			try {
				if (this.isPDF(file)) {
					this.loading = true;
					let formData = new FormData();
					formData.append('required_annex_id', this.annex?.id);
					formData.append('file', file, file?.name);
					await createAgreementAnnex(formData);
					this.$emit('change');
					this.$store.dispatch('agreement/setFlagRelevantChanges', true);
				}
			} catch (error) {
				showSnack(error?.response?.data?.message, 'error');
			} finally {
				this.loading = false;
				this.$refs.fileInput.value = null;
			}
		},
	},
};
</script>
