<template>
	<v-btn
		color="success"
		class="text-none"
		depressed
		outlined
		block
		:disabled="disabled"
		:loading="loadingSend"
		@click="confirmSend()"
	>
		Enviar a legal
	</v-btn>
</template>

<script>
import { showSnack } from '@/helpers/globalHelpers';
import { createSendContract } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'LegalSubmitButton',
	props: {
		agreementId: {
			type: [String, Number],
			required: false,
		},
		supplierId: {
			type: [String, Number],
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			loadingSend: false,
		};
	},
	methods: {
		confirmSend: async function () {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Deseas enviar el precontrato al área de Legal?',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
			});
			if (isConfirmed) {
				this.sendLegal();
			}
		},

		sendLegal: async function () {
			try {
				this.loadingSend = true;
				const params = {
					header: { agreement_id: parseInt(this.agreementId) },
				};
				await createSendContract(params);
				showSnack('El precontrato ha sido enviado al área de Legal', 'success');
				this.$emit('update');
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingSend = false;
			}
		},
	},
};
</script>

<style lang="scss"></style>
