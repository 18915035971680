import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'TemplateChangesTable',
	props: {
		agreementId: {
			type: [Number, String],
			required: true,
		},
		templates: {
			type: Array,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		redirectRouteName: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			headersAgreementWord: [
				{
					text: 'Fecha',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Ir a plantilla',
					value: 'actions',
					sortable: false,
					align: 'right',
					class: 'primary--text',
				},
			],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		formatDate,
	},
	beforeDestroy() {},
	components: {},
};
