import { formatDate, isArray, showSnack } from '@/helpers/globalHelpers';
import AnnexUpload from '@/components/agreement/elements/AnnexUpload/AnnexUpload.vue';
import {
	deleteAgreementAnnexFile,
	listAgreementAnnex,
	updateAgreementAnnex,
} from '@/store/modules/agreement/service/agreement.service';
import { mapActions, } from 'vuex';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'AnnexSection',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		agreementId: {
			type: [Number, String],
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		mode: {
			type: String,
			required: false,
			// PROVIDER, SUPPLY
		},
	},
	data() {
		return {
			headers: [
				{
					text: 'Descripción',
					value: 'name',
					sortable: false,
					align: 'left',
				},
				{
					text: 'Anexo para proveedor',
					value: 'requiredProvider',
					sortable: false,
					align: 'left',
				},
				{
					text: 'Fecha de Carga',
					value: 'date',
					sortable: false,
					align: 'center',
				},
				{
					text: 'Funciones',
					value: 'file',
					sortable: false,
					align: 'right',
				},
			],
			loading: false,
			annexes: [],
			readFile:false,
		};
	},
	created() {
		this.getAgreementAnnex();
	},
	mounted() { },
	computed: {
		isCompletedAnnexes: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},

		items() {
			let items = this.agreement?.lastNotification?.approvers;
			items = Array.isArray(items) ? items : [];
			return items;
		},

		isUploadedAnnexesIncomplete() {
			const flagValue = this.mode == 'PROVIDER';
			const result = this.annexes.some(
				(e) =>
					e?.flag_mandatory == true &&
					e?.agreement_annex == null &&
					e?.flag_required_for_provider == flagValue
			);
			return result;
		},
	},
	watch: {},
	methods: {
		...mapActions('contract', ['signedFileUrl']),

		formatDate,

		async getAgreementAnnex() {
			try {
				this.loading = true;
				const queryParams = {
					agreement_id: this.agreementId,
				};
				const response = await listAgreementAnnex(queryParams);
				this.annexes = isArray(response?.data);
			} catch (error) {
				showSnack(error?.response?.data?.message, 'error');
			} finally {
				this.isCompletedAnnexes = !this.isUploadedAnnexesIncomplete;
				this.loading = false;
			}
		},

		async listenCheckbox(value, index, item) {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Estás seguro que deseas realizar esta modificación?',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#0039a6',
				confirmButtonColor: '#e0e0e0',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.sendUpdateAgreementAnnex(item, value);
			} else {
				this.annexes[index].flag_required_for_provider = !value;
			}
		},

		async sendUpdateAgreementAnnex(agreementAnnex, value) {
			try {
				const bodyParams = {
					flag_required_for_provider: value,
				};
				await updateAgreementAnnex(agreementAnnex?.id, bodyParams);
				this.getAgreementAnnex();
				this.$store.dispatch('agreement/setFlagRelevantChanges', true);
			} catch (error) {
				showSnack(error?.response?.data?.message, 'error');
			}
		},

		async confirmFileDeletion(item) {
			const { isConfirmed } = await this.$swal.fire({
				title: null,
				text: '¿Estás seguro que deseas eliminar el archivo?',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#0039a6',
				confirmButtonColor: '#e0e0e0',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.removeAnnexFile(item);
			}
		},

		async removeAnnexFile(annex) {
			try {
				await deleteAgreementAnnexFile(annex?.agreement_annex?.id);
				this.getAgreementAnnex();
				this.$store.dispatch('agreement/setFlagRelevantChanges', true);
			} catch (error) {
				showSnack(error?.response?.data?.message, 'error');
			}
		},

		isEnabledUpload(item) {
			if (this.disabled) {
				return false;
			} else if (this.mode === 'SUPPLY' && !item?.agreement_annex) {
				return true;
			} else if (
				this.mode === 'PROVIDER' &&
				!item?.agreement_annex &&
				item?.flag_required_for_provider === true
			) {
				return true;
			}
			return false;
		},

		async getSignedUrl(fileUrl) {
			this.readFile = true;
			const { error, response } = await this.signedFileUrl({ fileUrl: fileUrl });
			if (error) showError(error);

			const url = this.$router.resolve({
				name: 'ReadFile',
				query: { url: response.signedUrl },
			}).href;

			window.open(url, '_blank');
			this.readFile = false;
		},
	},
	beforeDestroy() { },
	components: {
		AnnexUpload,
	},
};
