import { $EventBus } from '@/main';
import { deleteAgreement } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'DeleteAgreementButton',
	props: {
		agreementId: {
			type: [String, Number],
			required: false,
		},
		onDelete: {
			type: Function,
			required: false,
		},
	},
	data() {
		return {
			loading: false,
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		async removeContract() {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro de que quieres eliminar?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.sendDeletion();
			}
		},

		async sendDeletion() {
			try {
				this.loading = true;
				await deleteAgreement(this.agreementId);
				$EventBus.$emit('showSnack', 'success', 'Registro eliminado');
				this.$router.push({ name: 'SearchAgreement' });
			} catch (error) {
				this.showError(error?.response || error.toString());
			} finally {
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			if (msmError) {
				msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			} else {
				msmError = error;
			}
			$EventBus.$emit('showSnack', 'error', msmError);
		},
	},
	components: {},
};
