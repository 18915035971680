import { formatDate, showSnack } from '@/helpers/globalHelpers';
import { listAgreementObservations } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'ContractSheetTable',
	props: {
		agreementId: {
			type: [Number, String],
			required: true,
		},
		status: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			expanded: [],
			singleExpand: true,
			observations: [],
			loadingObservations: false,
			headers: [
				{
					text: 'Fecha',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{ text: '', value: 'data-table-expand' },
			],
		};
	},
	created() {
		this.getObservations();
	},
	mounted() {},
	computed: {
		isObservationsEmpty: function () {
			return Array.isArray(this.observations) && this.observations.length === 0;
		},
	},
	watch: {},
	methods: {
		formatDate,

		getObservations: async function () {
			try {
				this.loadingObservations = true;
				const response = await listAgreementObservations({
					agreement_id: this.agreementId,
				});
				this.observations = Array.isArray(response?.data) ? response?.data : [];
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingObservations = false;
			}
		},

		getUserObservations: function (observation) {
			return observation?.user?.fullName || '-';
		},
	},
	beforeDestroy() {},
	components: {},
};
